<template>
  <a-modal
    :visible="visible"
    title="新增看板分類"
    okText="確認新增"
    cancelText="取消"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form
      :model="formState"
      layout="vertical"
      autocomplete="off"
    >
      <a-row>
        <a-col :span="24">
          <a-form-item
            label="看板分類名稱"
            name="name"
            :rules="[{ required: true, message: '請輸入看板分類名稱' }]"
          >
            <a-input v-model:value="formState.name" size="large" />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { message } from 'ant-design-vue'
import api from '@/apis'

export default defineComponent({
  props: ['visible'],
  components: {},
  setup() {
    const formState = ref({
      name: ''
    });

    return {
      /* Data */
      formState,

      /* Api */
      api
    }
  },
  methods: {
    async handleOk() {
      if (!this.formState.name) {
        message.error('請輸入看板分類名稱');
        return
      }

      try {
        await this.api.v1.forum.createCategory(this.formState);
        message.success('新增看板分類成功');
      } catch (error) {
        console.log(error);
        message.error('新增看板分類發生錯誤，請稍後再試');
      }

      this.$emit('close');
      this.formState.name = '';
    },
    handleCancel() {
      this.$emit('close');
      this.formState.name = '';
    }
  }
})
</script>