<template>
  <a-layout-header class="layout-header">
    <a-row>
      <a-col :span="24">
        <a-button
          class="header-menu-btn"
          type="link" shape="circle"
          @click="appStore.collapsed=!appStore.collapsed"
        >
          <template #icon>
            <span class="mdi mdi-menu" :style="{ color: '#212121', fontSize: '1.5rem' }"></span>
          </template>
        </a-button>
        
        <div class="header-title">知識學堂</div>
      </a-col>
    </a-row>
  </a-layout-header>

  <a-layout-content class="layout-content">
    <div class="app-forum-page">
      <a-row :gutter="24">
        <a-col
          class="page__main"
          :sm="24" :md="18"
        >
          <a-card
            v-for="article in articlesData"
            class="forum-article-card"
            :bordered="false"
          >
            <a-row>
              <a-col :flex="device.mobile ? '40px' : '80px'" align="center">
                <div class="card-sidebar-actions">
                  <div>
                    <span
                      @click="setArticleReaction(article, 'up')"
                      class="action-btn mdi mdi-arrow-up"
                      :class="{'reaction--up': article.my_reaction && article.my_reaction.reaction == 'up'}"
                    />
                  </div>
                  <div
                    class="reaction-score my-1"
                    :class="[
                      { 'reaction--up': article.my_reaction && article.my_reaction.reaction == 'up'},
                      { 'reaction--down': article.my_reaction && article.my_reaction.reaction == 'down'}
                    ]"
                  >
                    {{ article.reaction_score }}
                  </div>
                  <div>
                    <span
                      @click="setArticleReaction(article, 'down')"
                      class="action-btn mdi mdi-arrow-down"
                      :class="{'reaction--down': article.my_reaction && article.my_reaction.reaction == 'down'}"
                    />
                  </div>
                </div>
              </a-col>
              
              <a-col :class="device.mobile ? 'px-2' : 'px-4'" flex="1">
                <div class="card-title" @click="toArticlePage(article.id)">{{ article.title }}</div>
                <div class="card-digest">{{ article.summary }}</div>

                <a-divider :style="{ margin: '12px 0' }" />

                <div class="card-info">
                  <a-row>
                    <a-col :xs="24" :md="12">
                      <a-avatar
                        :src="article.author.avatar!=null ? article.author.avatar.uri : require('@/assets/icons/avatar.png')"
                        :size="24"
                        :style="{ marginBottom: '5px', marginRight: '12px' }"
                      />
                      <a-typography-text type="secondary">由 </a-typography-text>
                      <a-typography-link :style="{ color: '#212121' }">{{ article.author.name }}</a-typography-link>
                      <a-typography-text type="secondary"> 發佈</a-typography-text>
                      <a-typography-text class="mx-2" type="secondary" :style="{ fontSize: '1.2rem' }">·</a-typography-text>
                      <a-typography-link>{{ article.category.name }}</a-typography-link>
                      
                      <template v-if="!device.mobile">
                        <a-typography-text class="mx-2" type="secondary" :style="{ fontSize: '1.2rem' }">·</a-typography-text>
                        <a-typography-text type="secondary">{{ DateUtil.formatDatetime(article.created_datetime) }}</a-typography-text>
                      </template>
                    </a-col>

                    <!-- <a-col :xs="24" :md="12" align="right">
                      <span class="mr-1 mdi mdi-comment-processing-outline" :style="{ fontSize: '1rem', color: 'rgba(0, 0, 0, 0.45)' }" />
                      <span class="mb-2" :style="{ color: 'rgba(0, 0, 0, 0.45)' }">15 則討論</span>
                    </a-col> -->
                  </a-row>
                </div>
              </a-col>
            </a-row>
          </a-card>
        </a-col>

        <a-col
          v-if="!device.mobile"
          class="page__sidebar"
          :md="6"
        >
          <div class="sidebar-actions">
            <a-button class="action-btn" type="primary" @click="toEditorPage">
              <span class="mdi mdi-plus mr-2" />
              發表文章
            </a-button>
          </div>

          <div class="sidebar-categories">
            <a-typography-title :level="5">文章分類
              <a-button type="text" shape="circle" @click="createCategoryModal.visible=true">
                <template #icon>
                  <span class="mdi mdi-plus" />
                </template>
              </a-button>
            </a-typography-title>
            
            <a-button
              v-for="catItem in categories"
              class="category-btn"
            >
              {{ catItem.name }}
            </a-button>
          </div>
        </a-col>
      </a-row>
    </div>
  </a-layout-content>

  <CreateCategoryModal
    :visible="createCategoryModal.visible"
    @close="handleCreateCategoryModalClose"
  />
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { createDeviceDetector } from 'next-vue-device-detector'
import { useAuthStore } from '@/store/auth'
import { useAppStore } from '@/store/app'
import api from '@/apis'
import DateUtil from '@/utils/DateUtil'
import CreateCategoryModal from '@/components/App/Forum/CreateCartegoryModal.vue'

export default defineComponent({
  components: {
    CreateCategoryModal
  },
  setup() {
    const device = createDeviceDetector();
    
    /* Store */
    const authStore = useAuthStore();
    const appStore = useAppStore();

    /* Data */
    const categories = ref([]);
    const articlesData = ref([]);

    /* Util Function */
    const getSummary = (content) => {
      var htmlObject = document.createElement('div');
      htmlObject.innerHTML = content;
      let children = htmlObject.children;
      let summary = '';
      if (children.length > 1) {
        summary = children[0].innerText + ' ' + children[1].innerText;
      } else if (children.length == 1) {
        summary = children[0].innerText;
      }
      return summary
    }

    /* Api */
    const getCategories = (async () => {
      const response = await api.v1.forum.categoryList();
      categories.value = response.data.result;
      console.log(categories.value)
    });

    const getArticles = (async () => {
      articlesData.value = [];

      const response = await api.v1.forum.articleList();
      console.log(response.data.result);
      response.data.result.forEach(article => {
        article.summary = getSummary(article.content);

        // set reaction
        article.my_reaction = null;
        article.reaction_score = 0;
        article.reactions.forEach(reaction => {
          if (reaction.reaction == 'up') article.reaction_score += 1;
          else if (reaction.reaction == 'down') article.reaction_score -= 1;

          if (reaction.user_id == authStore.info.uid) {
            article.my_reaction = reaction;
          }
        });

        articlesData.value.push(article);
      });


      // articlesData.value = response.data.result;
      console.log(articlesData.value)
    }); 

    /* Mounted */
    onMounted(() => {
      getCategories();
      getArticles();
    });

    return {
      device,

      /* Store */
      authStore,
      appStore,

      /* Data */
      categories,
      articlesData,

      /* Api */
      api,
      getCategories,

      /* Utils */
      DateUtil
    }
  },
  data() {
    return {
      /* Create Category Modal */
      createCategoryModal: {
        visible: false
      }
    }
  },
  methods: {
    /* Router */
    toEditorPage() {
      this.$router.push({ name: 'AppForumEditor' });
    },
    toArticlePage(id) {
      this.$router.push({
        name: 'AppForumArticle',
        params: {
          article_id: id
        }
      });
    },

    /* Functions */
    async setArticleReaction(article, reaction) {
      // 如果沒有紀錄, 新增 reaction
      if (article.my_reaction == null) {
        const response = await this.api.v1.forum.insertOneArticleReaction(article.id, {
          reaction: reaction
        });

        this.articlesData.forEach(item => {
          if (item.id == article.id) {
            const reactionItem = {
              id: response.data.result.id,
              reaction: reaction
            }
            item.my_reaction = reactionItem;
            item.reaction_score += 1;
          }
        });
        return
      }

      console.log(article)

      // 更新 reaction
      if (article.my_reaction.reaction == reaction) return
      else {
        await this.api.v1.forum.updateOneArticleReaction(article.id, {
          id: article.my_reaction.id,
          reaction: reaction
        });

        this.articlesData.forEach(article => {
          article.my_reaction.reaction = reaction;

          if (reaction == 'up') article.reaction_score += 2;
          else article.reaction_score -= 2;
        });
      }
    },

    /* Create Category Modal */
    handleCreateCategoryModalClose() {
      this.createCategoryModal.visible = false;
      this.getCategories();
    }
  }
})
</script>

<style lang="scss">
.app-forum-page {
  height: calc( 100vh - 98.5px );
  
  .page__main, .page__sidebar {
    height: calc( 100vh - 98.5px );
    overflow: auto;
  }

  .page__sidebar {
    .sidebar-actions {
      margin-bottom: 20px;

      .action-btn {
        width: 100%;
        height: 36px;
      }
    }

    .sidebar-categories {
      .category-btn {
        width: 100%;
        height: 36px;
        margin-bottom: 8px;
      }
    }
  }
}

.forum-article-card {
  margin-bottom: 16px;

  .ant-card-body {
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 16px !important;

    /* md */
    @media screen and (min-width: 970px) {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  .card-sidebar-actions {
    .reaction-score {
      color: #212121;
      font-size: 1.3rem;
    }

    .action-btn {
      color: #757575;
      font-size: 1.5rem;
      cursor: pointer;
    }

    .reaction--up {
      color: #1976D2;
    }

    .reaction--down {
      color: #F44336;
    }
  }

  .card-title {
    color: #212121;
    font-size: 1.45rem;
    font-weight: 500;
    letter-spacing: 0.01rem;
    cursor: pointer;
    margin-bottom: 12px;
  }
  
  .card-digest {
    font-size: 400;
    color: #757575;
    font-size: 1rem;
    line-height: 1.8rem;
    margin-bottom: 4px;

    overflow: hidden;  
    text-overflow: ellipsis;  
    white-space: normal;  
    display: -webkit-box;  
    -webkit-box-orient: vertical;  
    -webkit-line-clamp: 4; /*最多顯示幾行*/  
  }
}
</style>